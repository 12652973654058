//https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.gestureHandling
//https://tomchentw.github.io/react-google-maps/#googlemap
import React, { useCallback, useEffect, useState } from "react";
import ZoomInMapTwoToneIcon from "@mui/icons-material/ZoomInMapTwoTone";
import { APIProvider, Map as GoogleMap, MapProps } from "@vis.gl/react-google-maps";
import { BaseButton } from "@/src/component/base";
import { KEYS, LA, NYC } from "@/src/const";
import { useBreakPointDown } from "@/src/hook";
import { BlaceV2API, External } from "@/src/service";
import { Search } from "@/src/type/blaceV2/search/SearchType";
import { SharedConfigManager } from "@/src/util";
import styles from "./Map.module.scss";
import { MapMarker } from "./components/MapMarker";
import { MarkerPreview } from "./components/MarkerPreview";

const DEFAULT_LOCATION = {
  NYC: {
    lat: 40.748817,
    lng: -73.985428,
    states: ["NY", "NJ", "CT", "MA", "PA", "NH", "VT", "DE", "MD"],
  },
  LA: {
    lat: 34.058605,
    lng: -118.413124,
    states: ["CA", "WA", "OR", "NV", "AZ", "UT", "CO", "ID", "MT", "WY", "NM"],
  },
};

export type MapLocationMarker = {
  lat: number;
  lng: number;
  listingData?: Search;
  to?: string;
  slug?: string;
};

interface Props {
  markers?: MapLocationMarker[];
  centerMarker?: MapLocationMarker;
  markerOnClick?: (marker: MapLocationMarker) => void;
  hideMapButton?: boolean;
  handleMapToggle?: () => void;
  userMarkers?: MapLocationMarker[];
  visible?: boolean;
  defaultZoom?: number;
  additionalOptions?: MapProps;
  city?: string;
  hoveredSlug?: string;
  markerSize?: number;
  isMarkerOldVersion?: boolean;
}

function Map({
  markers,
  centerMarker,
  hideMapButton,
  defaultZoom,
  visible,
  additionalOptions,
  city,
  hoveredSlug,
  markerSize,
  isMarkerOldVersion,
  markerOnClick,
  handleMapToggle,
}: Props) {
  const isMobile = useBreakPointDown("md");
  const [showMap, setShowMap] = useState(false);
  const [zoom, setZoom] = useState(defaultZoom ?? 13);
  const [zoomLoaded, setZoomLoaded] = useState(false);
  const [coordinates, setCoordinates] = useState<MapLocationMarker | undefined>(centerMarker);
  const [hoverId, setHoverId] = useState("");
  const [selectedMarkerData, setSelectedMarkerData] = useState<
    (MapLocationMarker) | undefined
  >(undefined);
  const [selectedMarker, setSelectedMarker] = useState<
    google.maps.marker.AdvancedMarkerElement | undefined
  >(undefined);

  const onMarkerEnter = useCallback((id?: string) => setHoverId(id || ""), []);

  const onMarkerLeave = useCallback(() => setHoverId(""), []);

  const onMarkerClick = useCallback(
    async (
      marker?: MapLocationMarker,
      markerElement?: google.maps.marker.AdvancedMarkerElement,
    ) => {
      if (!marker?.listingData?.slug) return;

      const isDifferentMarker = marker.listingData.slug !== selectedMarkerData?.listingData?.slug;
      setSelectedMarkerData(isDifferentMarker ? marker : undefined);

      if (markerElement) {
        setSelectedMarker(isDifferentMarker ? markerElement: undefined);
      }

    },
    [selectedMarkerData],
  );

  const closeMarkerPreview = useCallback(() => {
    setSelectedMarker(undefined);
    setSelectedMarkerData(undefined);
  }, []);

  const handleMarkerPreviewClick = () => {
    if (typeof markerOnClick === "function" && selectedMarkerData) {
      markerOnClick({
        lat: selectedMarkerData?.lat,
        lng: selectedMarkerData?.lng,
        to: selectedMarkerData?.to,
      });
    }
  };

  useEffect(() => {
    if (zoomLoaded || defaultZoom) {
      return;
    }

    if (isMobile) {
      setZoom(11);
      setZoomLoaded(true);
    }
  }, [isMobile, defaultZoom]);

  /**
   * This prevents the component from loading immediately when the map is not needed
   */
  useEffect(() => {
    if (visible) {
      setShowMap(true);
    }
  }, [visible]);

  /**
   * use google geo location to approximate user location
   * this is not perfect and use cell towers / wifi to find location
   */
  useEffect(() => {
    if (!visible || coordinates?.lat || city) {
      return;
    }

    External.GoogleMapsAPI.geoLocate().then(async (response: any) => {
      if (!response.failure) {
        const reverseGeoCodeResponse = await BlaceV2API.GoogleServiceV2.getGoogleReverseGeoCode(
          response.location.lat,
          response.location.lng,
        );

        //TODO: consider a more type safe approach for this
        if ((reverseGeoCodeResponse?.body?.payload?.results ?? []).length > 0) {
          const result = reverseGeoCodeResponse?.body?.payload?.results[0];
          for (const addressComponent of result.address_components) {
            if (DEFAULT_LOCATION.NYC.states.includes(addressComponent.short_name)) {
              setCoordinates(DEFAULT_LOCATION.NYC);
              return;
            }

            if (DEFAULT_LOCATION.LA.states.includes(addressComponent.short_name)) {
              setCoordinates(DEFAULT_LOCATION.LA);
              return;
            }
          }
        }
      }

      //fallback to NYC if not other coordinates set
      setCoordinates(DEFAULT_LOCATION.NYC);
    });
  }, [visible, city, coordinates]);

  useEffect(() => {
    if (city === LA) {
      setCoordinates(DEFAULT_LOCATION.LA);
    } else if (city === NYC) {
      setCoordinates(DEFAULT_LOCATION.NYC);
    }
  }, [city]);

  if (!coordinates || !showMap) {
    return <></>;
  }

  return (
    <APIProvider apiKey={SharedConfigManager.getValue(KEYS.GOOGLE_MAPS_KEY)}>
      {typeof handleMapToggle === "function" && !hideMapButton && (
        <div className={styles.mapTurnOnOffBttnContainer}>
          <div className={styles.mapTurnOnOffBttnInner}>
            <BaseButton onClick={handleMapToggle} className={styles.toggleMapBtn}>
              <ZoomInMapTwoToneIcon /> Hide Map
            </BaseButton>
          </div>
        </div>
      )}
      <GoogleMap
        key={JSON.stringify(coordinates)}
        defaultCenter={coordinates}
        defaultZoom={zoom}
        mapId="locationsMap"
        onClick={closeMarkerPreview}
        className={styles.detailsMap}
        gestureHandling={"cooperative"}
        disableDefaultUI
        zoomControl
        scrollwheel={true}
        streetViewControl={false}
        {...additionalOptions}
      >
        {markers?.map((marker, m) => (
          <MapMarker
            key={`${marker.listingData?.slug}-${m}`}
            marker={marker}
            isHovered={[hoverId, selectedMarkerData?.listingData?.slug, hoveredSlug].includes(marker.listingData?.slug)}
            size={markerSize}
            isOldVersion={isMarkerOldVersion}
            onClick={onMarkerClick}
            onMouseEnter={onMarkerEnter}
            onMouseLeave={onMarkerLeave}
          />
        ))}
        {selectedMarker && selectedMarkerData?.listingData && (
          <MarkerPreview
            markerAnchor={selectedMarker}
            markerData={selectedMarkerData.listingData}
            onClick={handleMarkerPreviewClick}
            onClose={closeMarkerPreview}
          />
        )}
      </GoogleMap>
    </APIProvider>
  );
}

export default React.memo(Map);
